<script>
import APIRequests from '@/mixins/api-requests';

export default {
  name: 'KDAVacancyList',

  mixins: [APIRequests],

  data: () => ({
    m_strNoVacancy: 'There are no job vacancy at the moment. Please come back at another time'
  }),

  computed: {
    m_arrVacancies() {
      if (this.g_arrVacancies?.length) {
        return this.g_arrVacancies;
      }

      return [];
    }
  },

  mounted() {
    this.getVacancyList();
  }
};
</script>
